//@ts-ignore
import Logo from "../../assets/images/logo.png";


import './index.scss'
import {Link, NavLink} from "react-router-dom";
//@ts-ignore
import FeatherRight from "../../assets/images/Shape-10.png";
//@ts-ignore
import FeatherLeft from "../../assets/images/Shape-9.png";

export default function Header() {
    return (
        <header>
            {/*<img src={Logo} style={{height:  50}} alt={''}/>*/}
            <Link to={'/wedding'} style={{textDecoration: 'none', color: 'inherit'}}>
                <div className={'main-name-wrapper'} style={{display: 'flex', flexDirection: 'column', gap: 0}}>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                        <p className={'main-names nithin berkshire-swash-regular'} style={{fontSize: 20}}>
                            Nithin &
                        </p>
                        <img src={FeatherRight} alt={''} style={{width: 60, paddingLeft: 16}}/>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <img src={FeatherLeft} style={{width: 60, paddingRight: 16}}/>
                        <p className={'main-names aishu berkshire-swash-regular'} style={{fontSize: 20}}>
                            Aiswarya
                        </p>

                    </div>
                </div>
            </Link>
            <div style={{width: '1px', background: 'rgb(226, 227, 207)', marginLeft: 16}}
                 className={'header-border'}></div>
            <div className={'header-nav-items berkshire-swash-regular'}>
                <ul>
                    <li>
                        <NavLink to={'/wedding/events'}>
                            Events & Photos
                        </NavLink>
                    </li>
                </ul>
            </div>
        </header>
    )
}