import {Route, Routes} from "react-router-dom";

import {HomePage, NotFound} from '../pages'
import {Events} from '../components'

export default function RootRoute() {
    return (
        <Routes>
            <Route path='/wedding' element={<HomePage/>}/>
            <Route path='*' element={<NotFound/>}/>
            <Route path='/wedding/events' element={<Events/>}/>
        </Routes>
    )
}
