// @ts-nocheck
import {Countdown, Header} from '..'

import R from '../../assets/images/R.png'
import ImageBorder from '../../assets/images/Shape-1.png'
import Ring from '../../assets/images/ring.jpeg'
import FeatherRight from '../../assets/images/Shape-10.png'
import FeatherLeft from '../../assets/images/Shape-9.png'
import TopLeft from '../../assets/images/Shape-11.png'
import TopRight from '../../assets/images/Shape .png'
import TopBubble from '../../assets/images/Shape-2.png'
import TopRightLeaf from '../../assets/images/Shape-3.png'
import LeftScatteredBubbles from '../../assets/images/Shape-7.png'
import LeftBottomHalfBorder from '../../assets/images/Shape.png'
import LeftScatteredBottomBubbles from '../../assets/images/Shape-6.png'
import BottomLeaf from '../../assets/images/Shape-4.png'
import BottomShape from '../../assets/images/Shape15.png'
import UnderLine from '../../assets/images/Shape-8.png'
import MiddleBubbles from '../../assets/images/Shape-5.png'
import EngagementPhoto from '../../assets/images/engagement.png'

import './index.scss'

export default function Home() {
    return (
        <div className={'home-wrapper'}>
            <Header/>
            <div className={'share-components-wrapper'}>
                <img src={TopLeft} className={'shape-top-left'} alt={'shape-top-left'}/>
                <img src={TopRight} className={'shape-top-right'} alt={''}/>
                <img src={TopBubble} className={'shape-top-right-bubble'} alt={''}/>
                <img src={TopRightLeaf} className={'shape-top-right-leaf'} alt={''}/>
                <img src={LeftScatteredBubbles} className={'shape-left-scattered-bubble'} alt={''}/>
                <img src={LeftBottomHalfBorder} className={'shape-left-bottom-half-border'} alt={''}/>
                <img src={LeftScatteredBottomBubbles} className={'shape-left-scattered-bottom-bubbles'} alt={''}/>
                <img src={BottomLeaf} className={'shape-bottom-leaf'} alt={''}/>
                <img src={BottomShape} className={'shape-bottom'} alt={''}/>
                <img src={MiddleBubbles} className={'shape-middle-scattered-bubble'} alt={''}/>
            </div>
            <div className={'HomeComponent-Wrapper'}>
                <div  className={'first-flex-component'}>
                    <div
                        style={{background: `url(${EngagementPhoto})`, backgroundSize: 'cover'}}
                        className={'highlight-image'}
                    >
                        <img src={ImageBorder} alt={''} className={'border-image'}/>
                    </div>
                    <div className={'dummy-height-adjuster-div'}/>
                </div>
                <div className={'second-flex-component'}>
                    <div className={'main-name-wrapper'}>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                            <p className={'main-names nithin berkshire-swash-regular'}>
                                Nithin &
                            </p>
                            <img src={FeatherRight} alt={''} className={'shape-left-feather'}/>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <img src={FeatherLeft} className={'shape-right-feather'} alt={''}/>
                            <p className={'main-names aishu berkshire-swash-regular'}>
                                Aiswarya
                            </p>
                        </div>
                    </div>


                    <div className={'acme-regular invite-text'}>
                        Warmly Invite You to Celebrate The Joy of Our Marriage with Us
                    </div>

                    <div style={{display: 'flex', alignItems: 'center', zIndex: 1}} className={'save-the-date'}>
                        <p className={'acme-regular save-the-date-day'}>
                            Sunday
                        </p>
                        <p className={'berkshire-swash-regular save-the-date-date'}>
                            15
                        </p>
                        <p className={'acme-regular save-the-date-month'}>
                            December
                        </p>
                    </div>

                    <button className={'berkshire-swash-regular'} style={{
                        background: '#edede4',
                        outline: 'none',
                        border: 'none',
                        padding: '8px 32px',
                        fontSize: 18,
                        borderRadius: 40,
                        marginTop: 40,
                        marginBottom: 20,
                        zIndex: 1
                    }}>
                        Watch the event live
                    </button>
                    <div className={'shape-underline-wrapper'}>
                        <img src={UnderLine} className={'shape-underline'} alt={''}/>
                    </div>

                    <div style={{display: 'flex', zIndex: 1}}>
                        <p style={{color: '#afae98', fontSize: 50, paddingLeft: 16}}
                           className={'berkshire-swash-regular'}>
                            Save
                        </p>
                        <p style={{
                            color: '#afae98',
                            fontSize: 30,
                            paddingLeft: 16,
                            alignSelf: 'flex-end',
                            marginBottom: 6
                        }}
                           className={'berkshire-swash-regular'}>
                            the
                        </p>

                        <p style={{color: '#afae98', fontSize: 50, paddingLeft: 16}}
                           className={'berkshire-swash-regular'}>
                            Date
                        </p>
                    </div>
                    {/*<Countdown weddingDate={'15 December 2024'}/>*/}
                </div>
            </div>
        </div>
    )
}