import {Header} from "../";

//@ts-ignore
import Arrow from '../../assets/images/arrow-right.svg'
//@ts-ignore
import UnderLine from "../../assets/images/Shape-8.png";


import './index.scss'
import {Link} from "react-router-dom";


export default function Events() {
    return (
        <div className={'EventsWrapper'}>
            <Header/>
            {/*<div className={'border-image-gallery'}/>*/}
            <div className={'events-container'}>
                <div className={'event-title'}>
                    <h4>
                        Our Journey in Pictures
                    </h4>
                    <img src={UnderLine} className={'shape-underline'} alt={''}/>
                    <p className={'nunito'}>
                        Celebrate with us as we share the highlights of our most cherished moments. From the day we got
                        engaged, to the joy of our wedding, and the celebrations that followed—each moment has been
                        captured for you to enjoy. Click through to explore the galleries from our engagement, wedding,
                        and post-wedding events.
                    </p>
                </div>
                <div className={'events-gallery-container'}>
                    <Card month={'May'}
                          id={'engagement'}
                          time={'11:00 AM - 12:00 PM'}
                          date={12}
                          eventTitle={'Engagement'}
                          contextText={'A beautiful beginning to our forever. Explore the memories from our engagement day and relive the special moments with us.'}/>
                    <Card bgColor={'#717171'}
                          id={'wedding'}
                          color={'white'}
                          time={'11:00 AM - 12:00 PM'}
                          month={'December'}
                          date={15}
                          eventTitle={'Marriage'}
                          contextText={'The day we said \'I do\' marked the start of our forever. Step into our wedding day and experience the love, joy, and magic that surrounded us.'}/>
                    <Card month={'December'}
                          date={17}
                          id={'reception'}
                          time={'05:00 PM - 08:00 PM'}
                          eventTitle={'Wedding Reception'}
                          contextText={'A celebration of love and togetherness. Relive the laughter, dancing, and unforgettable moments from our reception as we celebrated with family and friends.'}/>
                </div>
            </div>
        </div>
    )
}

function Card(props: any) {
    const {bgColor, color, month, date, eventTitle, contextText, time, id}: any = props;
    return (
        <Link to={`/wedding/events/${id}`} style={{textDecoration: 'none', color: 'inherit'}}>
            <div className={'CardWrapper'} style={{backgroundColor: bgColor, color: color}}>
                <div>
                    <h6 className={'card-month nunito'}>{month}</h6>
                    <h4 className={'card-date nunito'}>{date}</h4>
                </div>
                <div className={'card-contain'}>
                    <p className={'card-title nunito'}>{eventTitle}</p>
                </div>
                <div style={{marginTop: 8, fontSize: 14}} className={'nunito'}>
                    {contextText}
                </div>
                <div className={'card-action'}>
                    <div>
                        <p className={'nunito'}> {time} </p>
                        <p className={'nunito'}> Offline</p>
                    </div>
                    <div style={{width: 30}}>
                        <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 12H18M18 12L13 7M18 12L13 17" stroke={bgColor ? color : "#000000"}
                                  stroke-width="2"
                                  stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
            </div>
        </Link>
    )
}